import FormatProperties from 'components/template-designer/types/formatProperties.type';
import LayerProperties from 'components/template-designer/types/layerProperties.type';
import { AnimationCopy, FigmaLayerCopy, LayerCopy, LayerStylingCopy } from 'components/template-designer/types/clipboard.type';
import Layer from '../types/layer.type';

/**
 * Type guard to check if a value is of type FormatProperties.
 * @param layer - Layer property.
 * @returns If a format is of type FormatProperties.
 */
export const isFormatProperties = (format: LayerProperties | FormatProperties): format is FormatProperties => {
    return !('properties' in format);
};

/**
 * Type guard to check if a value is of type LayerProperties.
 * @param format - Format property.
 * @returns If a format is of type LayerProperties.
 */
export const isLayerProperties = (layer: LayerProperties | FormatProperties): layer is LayerProperties => {
    return 'properties' in layer;
};

/**
 * Type guard to check if a value is of type LayerCopy.
 * @param layerCopy - Layer copy data from clipboard.
 * @returns If a layerCopy is of type LayerCopy[].
 */
export const isLayerCopy = (layerCopy: LayerCopy): layerCopy is LayerCopy => {
    return (
        layerCopy &&
        typeof layerCopy === 'object' &&
        !Array.isArray(layerCopy) &&
        'copyEvent' in layerCopy &&
        layerCopy.copyEvent === 'layerCopy' &&
        layerCopy.layers.length > 0
    );
};

/**
 * Type guard to check if a value is of type FigmaLayerCopy.
 * @param figmaLayerCopy - Layer copy data from clipboard.
 * @returns If a layerCopy is of type LayerCopy[].
 */
export const isFigmaLayerCopy = (figmaLayerCopy: FigmaLayerCopy): figmaLayerCopy is FigmaLayerCopy => {
    return (
        figmaLayerCopy &&
        typeof figmaLayerCopy === 'object' &&
        !Array.isArray(figmaLayerCopy) &&
        'copyEvent' in figmaLayerCopy &&
        figmaLayerCopy.copyEvent === 'figmaLayerCopy' &&
        figmaLayerCopy.layers.length > 0
    );
};

/**
 * Type guard to check if a value is of type LayerStylingCopy.
 * @param layerStylingCopy - Layer styling copy data from clipboard.
 * @returns If a layerStylingCopy is of type LayerStylingCopy.
 */
export const isLayerStylingCopy = (layerStylingCopy: LayerStylingCopy): layerStylingCopy is LayerStylingCopy => {
    return (
        !!layerStylingCopy &&
        typeof layerStylingCopy === 'object' &&
        !Array.isArray(layerStylingCopy) &&
        'copyEvent' in layerStylingCopy &&
        layerStylingCopy.copyEvent === 'layerStylingCopy'
    );
};

/**
 * Type guard to check if a value is of type AnimationCopy.
 * @param animationCopy - Animation copy data from clipboard.
 * @returns If a animationCopy is of type .
 */
export const isAnimationCopy = (animationCopy: AnimationCopy): animationCopy is AnimationCopy => {
    return (
        animationCopy &&
        typeof animationCopy === 'object' &&
        !Array.isArray(animationCopy) &&
        'copyEvent' in animationCopy &&
        animationCopy.copyEvent === 'animationCopy'
    );
};

/**
 * Type guard to check if a value is of type Layer with children.
 * @param layer - Layer
 * @returns If a layer has children.
 */
export const layerHasChildren = (layer: Layer): layer is Layer & { children: Layer[] } => {
    return (layer.children && layer.children.length > 0) || false;
};
