import { Color, SimpleColor } from 'types/color.type';
import { FormatHover } from './hover.type';
import { BorderStyleOptions, Media, TextProperties } from './layerProperties.type';
import Value from './value.type';

export enum LayoutGridUnitOptions {
    Px = 'px',
    '%' = '%'
}

export enum LayoutGridDirectionOptions {
    Left = 'left',
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom'
}

export enum ColumnsTypeOptions {
    Stretch = 'stretch',
    Left = 'left',
    Center = 'center',
    Right = 'right'
}

export enum RowTypeOptions {
    Stretch = 'stretch',
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom'
}

export enum LayoutGridOptions {
    Guidelines = 'guidelines',
    SquareGrid = 'squareGrid',
    Columns = 'columns',
    Rows = 'rows',
    RowsColumns = 'rowsColumns'
}

export interface Guideline {
    value: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    direction: LayoutGridDirectionOptions; // Direction of the guidelines origin.
    id: string; // Unique identifier.
}

export interface SquareGrid {
    gridSize: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
}

export interface Columns {
    count: number;
    type: ColumnsTypeOptions;
    gutter: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    margin: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    width: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    offset: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
}

export interface Rows {
    count: number;
    type: RowTypeOptions;
    gutter: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    margin: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    height: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
    offset: {
        value: number;
        unit: LayoutGridUnitOptions;
    };
}

export interface LayoutGrid {
    activeLayout: LayoutGridOptions;
    guidelines?: Guideline[];
    squareGrid?: SquareGrid;
    columns?: Columns;
    rows?: Rows;
    rowsColumns?: {
        options: {
            rows: {
                count: number;
                type: string;
                gutter: {
                    value: number;
                    unit: LayoutGridUnitOptions;
                };
                margin: {
                    value: number;
                    unit: LayoutGridUnitOptions;
                };
            };
            columns: {
                count: number;
                type: string;
                gutter: {
                    value: number;
                    unit: LayoutGridUnitOptions;
                };
                margin: {
                    value: number;
                    unit: LayoutGridUnitOptions;
                };
            };
        };
        lines: Guideline[];
    };
    color?: SimpleColor;
}

export interface SafeZone {
    activeLayout: string;
    color?: SimpleColor;
}

interface FormatProperties {
    padding?: {
        left: Value<string | number>;
        top: Value<string | number>;
        right: Value<string | number>;
        bottom: Value<string | number>;
    };
    background?: Color;
    backgroundImage?: Media;
    textStyling?: {
        normal: Pick<TextProperties['textStyling']['normal'], 'fontSize'>;
    };
    border?: {
        borderWidth: {
            left: Value<number>;
            right: Value<number>;
            top: Value<number>;
            bottom: Value<number>;
        };
        borderColor: SimpleColor;
        borderStyle: BorderStyleOptions;
    };
    customCSS?: string;
    hover?: FormatHover;
    visibility?: number[];
    layoutGrid?: LayoutGrid;
    safeZone?: SafeZone;
}

export default FormatProperties;
